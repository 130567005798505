<template>
  <app-form-select
    v-if="data"
    v-model="model"
    clearable
    item-value="id"
    :items="data"
    label="Place"
    :required="required"
  >
    <template #selection="{ item }">
      {{ item.name }}
    </template>

    <template #item="{ item, props }">
      <v-list-item
        v-bind="props"
        :subtitle="item.address.label"
        :title="item.name"
      />
    </template>
  </app-form-select>
</template>

<script lang="ts" setup>
const properties = defineProps<{
  modelValue?: number | null;
  required?: boolean;
}>();

const emit = defineEmits<{
  "update:modelValue": [value?: number];
}>();

const { data } = usePlaceList();
const model = computed({
  get() {
    return data.value?.find((place) => place.id === properties.modelValue);
  },
  set(value) {
    emit("update:modelValue", value?.id);
  },
});
</script>
