<template>
  <div class="flex gap-4">
    <app-form-input-datetime
      v-model="startDateModel"
      class="flex-auto"
      data-cy-start-date
      hide-details
      label="Start date"
      :name="startDateName"
      required
    />

    <app-form-input-datetime
      v-model="endDateModel"
      class="flex-auto"
      data-cy-end-date
      hide-details
      label="End date"
      :name="endDateName"
      required
      :rules="{
        gt: startDateModel,
      }"
    />
  </div>
</template>

<script lang="ts" setup>
const startDateModel = defineModel("start_date", { default: undefined });
const endDateModel = defineModel("end_date", { default: undefined });

const startDateName = computed(() => `start_date_${useId()}`);
const endDateName = computed(() => `end_date_${useId()}`);
</script>
